<template>
  <base-view class="loading-view" no-top-bottom-padding>
    <template #top>
      <the-header no-shadow is-wide no-second-section />
      <content-loading-indicator />
    </template>
    <template #bottom>
      <the-region-picker v-if="!remoteDataLoaded" />
      <the-help v-if="!remoteDataLoaded" />
      <the-footer is-visible-on-mobile />
    </template>
  </base-view>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import ContentLoadingIndicator from '@/components/ContentLoadingIndicator.vue'
import TheHelp from '@/components/TheHelp.vue'
import TheRegionPicker from '@/components/TheRegionPicker.vue'
import TheFooter from '@/components/TheFooter.vue'
import BaseView from '@/components/BaseView.vue'
import PrerendererService from '@/services/PrerendererService'

export default {
  components: {
    TheHeader,
    ContentLoadingIndicator,
    TheHelp,
    TheRegionPicker,
    TheFooter,
    BaseView
  },
  mounted() {
    if (PrerendererService.isPrerendererBrowser()) {
      document.head.querySelectorAll('link[rel=stylesheet]').forEach((elem) => {
        elem.remove()
      })
      document.head.querySelectorAll('script[src]').forEach((elem) => {
        elem.remove()
      })
      document.dispatchEvent(new CustomEvent('x-app-rendered')) // For spa prerender on build
    }
  },
  computed: {
    title() {
      return this.$t('login.hl_welcome')
    },
    remoteDataLoaded() {
      return this.$store.state.customer.remoteDataLoaded
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$root.$emit('route-entered', to.path, from.path)
      vm.$root.$emit('loading-view-entered', to.path, from.path)
    })
  }
}
</script>

<style lang="scss" scoped>
.loading-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000;
}
</style>
