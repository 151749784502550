var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-view", {
    staticClass: "loading-view",
    attrs: { "no-top-bottom-padding": "" },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function () {
          return [
            _c("the-header", {
              attrs: {
                "no-shadow": "",
                "is-wide": "",
                "no-second-section": "",
              },
            }),
            _c("content-loading-indicator"),
          ]
        },
        proxy: true,
      },
      {
        key: "bottom",
        fn: function () {
          return [
            !_vm.remoteDataLoaded ? _c("the-region-picker") : _vm._e(),
            !_vm.remoteDataLoaded ? _c("the-help") : _vm._e(),
            _c("the-footer", { attrs: { "is-visible-on-mobile": "" } }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }